import * as React from "react";
import { graphql } from "gatsby";
import Image from "gatsby-plugin-sanity-image";
import { Link } from "gatsby";
import styled from "styled-components";
import Layout from "../components/layout";
import Seo from "../components/seo";
import ImageCarousel from "../components/carousel";
import OrderButtons from "../components/orderButtons";

const OrderButtonsHolder = styled.div`
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  margin: 20px;

  a {
    background-color: darkred;
    color: white;
    padding: 10px;
    font-weight: 900;
    text-decoration: none;
    border-radius: 5px;
    margin-bottom: 10px;
  }
`;

const EventContainer = styled.div`
  .eventContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 30px 0px;
    border-bottom: 3px solid #ededed;

    .eventImageContainer {
      flex: 2;
      display: inline-block;
      border: 3px solid #ccc;
      background: #eee;
      margin: auto;
      padding: 10px 10px;

      @media only screen and (min-width: 600px) {
        &.right {
          transform: rotate(2deg);
        }
        &.left {
          transform: rotate(-2deg);
        }
      }
    }

    .eventImageContainer.left {
      display: block;
    }

    .eventImageContainer.right {
      display: none;
    }

    @media only screen and (min-width: 600px) {
      flex-direction: row;

      .eventImageContainer.left {
        display: none;
      }

      :nth-child(even) .eventImageContainer.left {
        display: block;
        flex: 2;
      }

      :nth-child(odd) .eventImageContainer.right {
        display: block;
        flex: 2;
      }
    }
    .eventDetails {
      flex: 1;
      @media only screen and (min-width: 600px) {
        flex: 3;
      }

      h3 {
        font-size: 24px;
        margin-bottom: 20px;
      }

      h4 {
        font-size: 28px;
        margin-bottom: 5px;
      }
    }

    .eventImage {
      width: 100%;
    }
  }
`;

const IndexPage = (data) => {
  return (
    <Layout title="Cartel Taco Bar">
      <Seo title="Home" keywords={[`taco`, ``]} />

      <ImageCarousel></ImageCarousel>

      <OrderButtonsHolder>
        <OrderButtons />
      </OrderButtonsHolder>

      <h4>
        Upcoming Event - <Link to="/events">View All</Link>
      </h4>
      <EventContainer>
        {/* <EventList limit={1} events={data.allEvent.nodes}></EventList> */}
      </EventContainer>
      <p>
        10best.com – Ten Terrific Places to Eat and Drink Near AT&T Stadium in
        Arlington The newest addition to the Urban Union complex in downtown
        Arlington, Texas, The Cartel Taco Bar has taken their love for Tacos,
        Tequila, and Arlington to create a new favorite spot for locals and
        tourists alike. With unique appetizers, specialty cocktails, and custom
        crafted tacos there is something for everyone, even your pets! (kick
        back on our dog-friendly patio and enjoy a few drinks). Located within
        two miles of AT&T Stadium, Globe Life Park, and Six Flags Over Texas we
        are the perfect stop to get your grub on before or after a big event.
        Come for the Tacos but check out the other amazing venues in the Urban
        Union complex, from Cidercade, Sugar Bee Sweets, or Dyno Rock Indoor
        Climbing, there is a lot to see and do in this up-and-coming area.
      </p>
    </Layout>
  );
};

export const query = graphql`
  query MyQuery {
    allSanityHomepage {
      nodes {
        name
        address
        state
        zip
        phone
        logo {
          ...ImageWithPreview
        }
      }
    }
  }
`;

export default IndexPage;
