import React from "react";
import { StaticQuery, graphql } from "gatsby";
import { Carousel } from "react-responsive-carousel";
import styled from "styled-components";
import Image from "gatsby-plugin-sanity-image";
const CarsouselContainer = styled(Carousel)`
  img {
    height: 300px;
    max-width: 600px;
    object-fit: cover;
  }
`;

function imageCarousel() {
  return (
    <StaticQuery
      query={graphql`
        query CarouselQuery {
          allSanityPhoto {
            nodes {
              image {
                ...ImageWithPreview
              }
              id
              category
              description
            }
          }
        }
      `}
      render={(data) => (
        <>
          <CarsouselContainer
            autoPlay={true}
            showStatus={false}
            showIndicators={true}
            showThumbs={false}
            infiniteLoop={true}
            showArrows={true}
          >
            {data.allSanityPhoto.nodes
              .filter((photo) => photo.category.includes("showOnHome"))
              .map((photo) => (
                <Image {...photo.image} alt={photo.description} />
              ))}
          </CarsouselContainer>
        </>
      )}
    ></StaticQuery>
  );
}

export default imageCarousel;
